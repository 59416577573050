import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// containers
import * as fromContainers from './containers';
import { WinterComponent } from './containers/winter/winter.component';

// Guards
import * as fromGuards from '@app/boxes/guards';
import {AuthGuard} from '@app/auth/guards/auth.guard';
import {ShipmentTrackingComponent} from '@app/pages/containers/shipment-tracking/shipment-tracking.component';

const routes: Routes = [
  // // { path: '', component: fromContainers.HomeComponent },
  // { path: 'faq', component: fromContainers.FaqComponent },
  // // { path: 'brad', redirectTo: '/gift' },
  // // { path: 'bogo', redirectTo: '/gift' },
  // {
  //   path: 'summer',
  //   component: fromContainers.SummerComponent,
  //   canActivate: [ AuthGuard, fromGuards.AllBoxesGuard ],
  // },
  // {
  //   path: 'winter',
  //   component: WinterComponent,
  //   canActivate: [ AuthGuard, fromGuards.AllBoxesGuard ],
  // },
  // { path: 'bogo', component: fromContainers.BogoComponent },
  // { path: 'about', component: fromContainers.AboutComponent },
  // { path: 'sales', component: fromContainers.SalesComponent },
  // { path: 'churn', component: fromContainers.ChurnComponent },
  // { path: 'claim', component: fromContainers.ClaimComponent },
  // { path: 'terms', component: fromContainers.TermsComponent },
  // { path: 'carecards', component: fromContainers.CareCardsComponent },
  // { path: 'inspiration', component: fromContainers.InspirationComponent },
  // { path: 'privacy-policy', component: fromContainers.PrivacyPolicyComponent },
  // { path: 'share', component: fromContainers.ShareComponent },
  // { path: 'backgrounds', component: fromContainers.BackgroundsPageComponent },
  // { path: 'everyplate', component: fromContainers.EveryPlateComponent },
  // {
  //   path: 'gift-done',
  //   component: fromContainers.GiftDoneComponent,
  // },
  // // { path: 'jobs', component: fromContainers.JobsComponent },
  // { path: 'jobs/:jobId', component: fromContainers.JobsComponent },
  // {
  //   path: 'shipment-tracking',
  //   component: ShipmentTrackingComponent,
  // }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ],
})
export class PagesRoutingModule { }
